<template>

  <v-container float:left;>
    <div>

    </div>
    <div>
      <div id="waveform"></div>
      <waveform cue="previewPlayer.currentCue"><img ng-click="jumpPreview($event, previewPlayer.currentCue)" ng-style="{ &quot;clip-path&quot;: &quot;inset(0% 0% 0%&quot;+(isPreviewCue(previewPlayer.currentCue) ? previewPlayer.progress : 0) +&quot;%)&quot;, &quot;-webkit-clip-path&quot;: &quot;inset(0% 0% 0% &quot;+(isPreviewCue(previewPlayer.currentCue) ? previewPlayer.progress : 0) +&quot;%)&quot; }" style="clip-path: inset(0% 0% 0% 51.3775%);" ng-src="https://preview.createmusic.com/waveform/7130400/7130451_original_full.png" src="https://preview.createmusic.com/waveform/7130400/7130451_original_full.png"><img class="waveform-overlay" ng-click="jumpPreview($event,previewPlayer.currentCue)" ng-style="{ &quot;clip-path&quot;: &quot;inset(0% &quot;+(isPreviewCue(previewPlayer.currentCue) ? 100-previewPlayer.progress : 100) +&quot;% 0% 0%)&quot;, &quot;-webkit-clip-path&quot;: &quot;inset(0% &quot;+(isPreviewCue(previewPlayer.currentCue) ? 100-previewPlayer.progress : 100) +&quot;% 0% 0%)&quot; }" style="clip-path: inset(0% 48.6225% 0% 0%);" ng-src="https://preview.createmusic.com/waveform/7130400/7130451_original_full.png" src="https://preview.createmusic.com/waveform/7130400/7130451_original_full.png"></waveform>
    </div>


  </v-container>

</template>

<script>
import WaveSurfer from 'wavesurfer'

export default {
  name: "BottomPlayer",
  components: {},
  data() {
    return {
      options: {},

    };

  },
  mounted() {
    var wavesurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: 'violet',
      progressColor: 'purple',
      height: '90',
      barWidth: "4",
      // backend: 'MediaElement'
    });
    wavesurfer.load('http://back.smartsound.com/previews/100/101.mp3');
    wavesurfer.on('ready', function () {
      wavesurfer.play();
    });
    this.$root.$on('selectedTrack', data => {
      wavesurfer.load(data.cuePreviewURL)
    });

  }
}
</script>


<style scoped>

</style>
