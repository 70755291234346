<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap>
      <v-data-iterator :items="tracks" :items-per-page="10">
        <div v-for="track in tracks" :key="track.id" xs12 md6 lg3>
        <v-card
            elevation="2"
        >{{track.cueName}}</v-card>

      </div>
      </v-data-iterator>

    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "SearchResults",
  props: [
    'tracks'
  ]
}
</script>

<style scoped>

</style>