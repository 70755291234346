<template>
  <v-container dark fluid>

    <v-simple-table v-if="filteredTracks" class="overflow-y-auto" dark>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Title
          </th>
          <th class="text-left">
            Album
          </th>
          <th class="text-left">
            Genres
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="track in filteredTracks"
            :key="track.cueName"
            @click="selectTrack(track)"
        >
          <td>{{ track.cueName }}</td>
          <td>{{ track.albumName }}</td>
          <td v-for="genre in track.cueKeywords.slice(0,3)" :key="genre.key">
            <v-chip x-small>{{ genre }}</v-chip>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>


</template>

<script>
export default {
  name: "FilteredItems",
  data: function () {
    return {
      // filteredTracks: []
    }
  },
  props: [
    'tracks',
    'searchString'
  ],
  computed: {
    filteredTracks() {
      if (this.searchString) {
        return this.tracks.filter((item) => {
          return this.searchString.toLowerCase().split(' ').every(v => item.cueName.toLowerCase().includes(v))
        }).sort(function (a, b) {
          var nameA = a.cueName.toUpperCase();
          var nameB = b.cueName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        }).slice(0, 100)
      } else {
        return this.resources;
      }
    }
  },
  methods: {
    selectTrack(track) {
      this.$root.$emit('selectedTrack', track);
    }
  }
}
</script>

<style scoped>

</style>
