<template>
  <v-app>

    <v-app-bar app
               fixed
               color="#6A76AB"
               dark
               shrink-on-scroll
               prominent
               src="https://images.unsplash.com/photo-1477233534935-f5e6fe7c1159?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"

    >
      <template v-slot:img="{ props }">
        <v-img
            v-bind="props"
            gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>

      <v-spacer></v-spacer>


      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tab>Tracks</v-tab>
          <v-tab>Tab 2</v-tab>
          <v-tab>Tab 3</v-tab>
        </v-tabs>
      </template>
      <v-container>
        <v-text-field
            v-model="searchString"
            solo
            label="Find Tracks ..."
            clearable
            @focus="InputFocused = false"
            @blur="InputFocused = false"
        ></v-text-field>
      </v-container>

    </v-app-bar>


    <v-main app>
      <v-container fluid dark>
        <FilteredItems :tracks="listOfTracks" :searchString="searchString"></FilteredItems>


        <SearchResults :tracks="listOfTracks" v-if="false"></SearchResults>
      </v-container>
    </v-main>

    <v-footer dark fixed max-height="100px">
      <BottomPlayer></BottomPlayer>
    </v-footer>

     <v-overlay v-if="databaseLoading">
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>


        </v-overlay>
  </v-app>

</template>

<script>
// import Searchbar from './components/Searchbar.vue'
import BottomPlayer from './components/BottomPlayer.vue'
import FilteredItems from "@/components/FilteredItems";
import SearchResults from "@/components/SearchResults"

import axios from 'axios';

export default {
  name: 'App',

  components: {
    // Searchbar,
    BottomPlayer,
    FilteredItems,
    SearchResults
  },

  data: () => ({
    overlay: false,
    databaseLoading: true,
    database: null,
    listOfTracks: [],
    InputFocused: false,
    searchString: "",
  }),
  beforeCreate() {
    axios
        .get('https://api.createmusic.com/search?search=')
        .then(response => {
          (this.database = response.data)
          this.listOfTracks = this.buildListofTracks(this.database)
          this.databaseLoading = false
        })
        .catch((response) => {
          console.log('catch response', response)
        });

  },
  methods: {
    buildListofTracks: function (database) {
      var listOfTracks = []
      var trackObject = database.cueData
      let track;
      for (track in trackObject) {
        listOfTracks.push(trackObject[track])
      }
      return listOfTracks
    },
  }
};
</script>
